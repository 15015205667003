import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';

type UserSettingsPanelFooterProps = {
    isSubmitDisabled?: boolean;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
};

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'UserSettingsFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for "Cancel" button in user settings form',
    },
    cancelButtonText: {
        id: 'UserSettingsFooter_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for "Cancel" button in user settings form',
    },
    saveButtonAriaLabel: {
        id: 'UserSettingsFooter_SaveButton_AriaLabel',
        defaultMessage: 'Save',
        description: 'Aria label for "Save" button in user settings form',
    },
    saveButtonText: {
        id: 'UserSettingsFooter_SaveButton_Text',
        defaultMessage: 'Save',
        description: 'Text for "Save" button in user settings form',
    },
});

/**
 * Styles
 */

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
        height: '100%',
    },
});

/**
 * END Styles
 */

export const UserSettingsPanelFooter: React.FC<UserSettingsPanelFooterProps> = (
    props: UserSettingsPanelFooterProps
) => {
    const { isSubmitDisabled, onCancelClicked, onSubmitClicked } = props;

    // Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, buttonContainerStyles.root)}>
            <div className={horizontalStackStyles.item}>
                <PrimaryButton
                    ariaLabel={formatMessage(messages.saveButtonAriaLabel)}
                    disabled={isSubmitDisabled}
                    onClick={onSubmitClicked}
                    text={formatMessage(messages.saveButtonText)}
                />
            </div>
            <div className={horizontalStackStyles.item}>
                <DefaultButton
                    ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                    onClick={onCancelClicked}
                    text={formatMessage(messages.cancelButtonText)}
                />
            </div>
        </div>
    );
};
