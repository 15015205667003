import {
    AnimationStyles,
    CommandBar,
    ICommandBarItemProps,
    IContextualMenuProps,
    IIconProps,
    PrimaryButton,
    ProgressIndicator,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAddDevBoxPanelContext, useAddEnvironmentPanelContext } from '../../hooks/context/panels';
import { ExtendedIcon } from '../../icons/initialize-extended-icons';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';
import { ActionBarState } from './models';
import ProjectPickerContainer from './project-picker/project-picker';
import ResourcePickerContainer from './resource-picker/resource-picker';
import { getActionBarState, getLoadingProgress } from './selectors';

interface ActionBarComponentProps {
    onAddDevBoxButtonClicked: () => void;
    onAddEnvironmentButtonClicked: () => void;
    actionBarState: ActionBarState;
    loadingProgress: number;
}

export const messages = defineMessages({
    newButtonAriaLabel: {
        id: 'ActionBar_NewButton_AriaLabel',
        defaultMessage: 'New',
        description: 'Aria label for "new" button',
    },
    newButtonText: {
        id: 'ActionBar_NewButton_Text',
        defaultMessage: 'New',
        description: 'Text for the "new" button',
    },
    addDevBoxButtonAriaLabel: {
        id: 'ActionBar_AddDevBoxButton_AriaLabel',
        defaultMessage: 'New dev box',
        description: 'Aria label for the "new dev box" button',
    },
    addDevBoxButtonText: {
        id: 'ActionBar_AddDevBoxButton_Text',
        defaultMessage: 'New dev box',
        description: 'Text for the "new dev box" button',
    },
    addEnvironmentButtonAriaLabel: {
        id: 'ActionBar_AddEnvironmentButton_AriaLabel',
        defaultMessage: 'New environment',
        description: 'Aria label for the "new environment" button',
    },
    addEnvironmentButtonText: {
        id: 'ActionBar_AddEnvironmentButton_Text',
        defaultMessage: 'New environment',
        description: 'Text for the "new environment" button',
    },
    progressIndicatorAriaLabel: {
        id: 'ActionBar_ProgressIndicator_AriaLabel',
        defaultMessage: 'Loading progress',
        description: 'Aria label for the progress indicator',
    },
    resourcePickerButtonAriaLabel: {
        id: 'ActionBar_ResourcePickerButton_AriaLabel',
        defaultMessage: 'Resource picker',
        description: 'Aria label for the resource picker',
    },
    projectPickerButtonAriaLabel: {
        id: 'ActionBar_ProjectPickerButton_AriaLabel',
        defaultMessage: 'Project picker',
        description: 'Aria label for the project picker',
    },
});

/**
 * Styles
 */

const rootStylesFactory = (isLoading: boolean) =>
    legacyMakeStyles((theme) => ({
        root: {
            borderBottom: isLoading ? 0 : `1px solid ${getSemanticColor(theme, 'actionBarBorder')}`,
            height: isLoading ? '2px' : '47px',
            padding: isLoading ? 0 : '0 16px',
            userSelect: 'none',
            zIndex: 1000,

            // This slide-down animation is applied to the action bar container once loading is complete. Gives the
            // appearance of the progress bar disappearing and the action bar sliding in.
            ...(isLoading
                ? {}
                : {
                      ...AnimationStyles.slideDownIn10,
                      animationDuration: '0.3s',
                  }),
        },
    }));

const useProgressBarContainerStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const useProgressBarStyles = makeStyles({
    itemProgress: {
        padding: 0,
    },
});

const useCommandBarStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
        height: '100%',
        padding: 0,
    },
}));

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        justifyContent: 'center',
        height: '100%',
    },
});

/**
 * END Styles
 */

const iconProps: IIconProps = {
    iconName: 'Add',
};

export const ActionBarComponent: React.FC<ActionBarComponentProps> = React.memo((props: ActionBarComponentProps) => {
    const { onAddDevBoxButtonClicked, onAddEnvironmentButtonClicked, actionBarState, loadingProgress } = props;

    // Intl hooks
    const { formatMessage, formatNumber } = useIntl();

    // Style hooks
    const isLoading = actionBarState === ActionBarState.Loading;
    const useRootStyles = rootStylesFactory(isLoading);
    const { root } = useRootStyles();
    const progressBarContainerStyles = useProgressBarContainerStyles();
    const progressBarStyles = useProgressBarStyles();
    const commandBarStyles = useCommandBarStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    const addDevBoxButtonProps = React.useMemo(
        () => ({
            ariaLabel: formatMessage(messages.addDevBoxButtonAriaLabel),
            iconProps,
            onClick: onAddDevBoxButtonClicked,
            text: formatMessage(messages.addDevBoxButtonText),
        }),
        [onAddDevBoxButtonClicked, formatMessage]
    );

    const addEnvironmentButtonProps = React.useMemo(
        () => ({
            text: formatMessage(messages.addEnvironmentButtonText),
            iconProps,
            ariaLabel: formatMessage(messages.addEnvironmentButtonAriaLabel),
            onClick: onAddEnvironmentButtonClicked,
        }),
        [onAddEnvironmentButtonClicked, formatMessage]
    );

    const menuProps: IContextualMenuProps = React.useMemo(
        () => ({
            items: [
                {
                    ...addDevBoxButtonProps,
                    key: 'addDevBox',
                    iconProps: { iconName: 'TVMonitor' },
                },
                {
                    ...addEnvironmentButtonProps,
                    key: 'addEnvironment',
                    iconProps: { iconName: ExtendedIcon.WebEnvironment },
                },
            ],
            directionalHintFixed: true,
        }),
        [formatMessage, addDevBoxButtonProps, addEnvironmentButtonProps]
    );

    const addDevBoxAndAddEnvironmentButtonProps = React.useMemo(
        () => ({
            ariaLabel: formatMessage(messages.newButtonAriaLabel),
            iconProps,
            menuProps,
            text: formatMessage(messages.newButtonText),
        }),
        [menuProps, formatMessage]
    );

    const buttonProps = React.useMemo(() => {
        switch (actionBarState) {
            case ActionBarState.CanCreateDevBoxesAndEnvironments:
                return addDevBoxAndAddEnvironmentButtonProps;
            case ActionBarState.CanCreateOnlyDevBoxes:
                return addDevBoxButtonProps;
            case ActionBarState.CanCreateOnlyEnvironments:
                return addEnvironmentButtonProps;
            default:
                return undefined;
        }
    }, [actionBarState, addDevBoxButtonProps, addEnvironmentButtonProps, addDevBoxAndAddEnvironmentButtonProps]);

    const actionBarItems: ICommandBarItemProps[] = React.useMemo(() => {
        return [
            {
                key: 'newButton',
                onRender: () => <PrimaryButton {...buttonProps} />,
            },
        ];
    }, [buttonProps]);

    const farItems: ICommandBarItemProps[] = React.useMemo(() => {
        return [
            {
                key: 'resourcePicker',
                onRender: () => (
                    <ResourcePickerContainer ariaLabel={formatMessage(messages.resourcePickerButtonAriaLabel)} />
                ),
            },
            {
                key: 'projectPicker',
                onRender: () => (
                    <ProjectPickerContainer ariaLabel={formatMessage(messages.projectPickerButtonAriaLabel)} />
                ),
            },
        ];
    }, []);

    if (
        actionBarState === ActionBarState.NotStarted ||
        actionBarState === ActionBarState.CannotCreateDevBoxesOrEnvironments
    ) {
        return <></>;
    }

    return (
        <div className={root}>
            <div className={mergeClasses(stackStyles.root, buttonContainerStyles.root)}>
                {isLoading ? (
                    <div className={mergeClasses(stackStyles.item, progressBarContainerStyles.root)}>
                        <ProgressIndicator
                            ariaLabel={formatMessage(messages.progressIndicatorAriaLabel)}
                            ariaValueText={formatNumber(loadingProgress, {
                                maximumFractionDigits: 0,
                                style: 'percent',
                            })}
                            percentComplete={loadingProgress}
                            styles={progressBarStyles}
                        />
                    </div>
                ) : (
                    <div className={stackStyles.item}>
                        <CommandBar items={actionBarItems} farItems={farItems} styles={commandBarStyles} />
                    </div>
                )}
            </div>
        </div>
    );
});

export const ActionBarContainer: React.FC = () => {
    // Application state hooks
    const actionBarState = useSelector(getActionBarState);
    const loadingProgress = useSelector(getLoadingProgress);

    // Context hooks
    const { openSurface: onAddDevBoxButtonClicked } = useAddDevBoxPanelContext();
    const { openSurface: onAddEnvironmentButtonClicked } = useAddEnvironmentPanelContext();

    return (
        <ActionBarComponent
            onAddDevBoxButtonClicked={onAddDevBoxButtonClicked}
            onAddEnvironmentButtonClicked={onAddEnvironmentButtonClicked}
            actionBarState={actionBarState}
            loadingProgress={loadingProgress}
        />
    );
};

export default ActionBarContainer;
