import { defineMessages } from '@formatjs/intl';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DevBoxState } from '../../../redux/store/dev-box-state';
import { AriaLiveWrapper } from '../../common/accessibility/aria-live-wrapper';
import InProgressStateMessage from '../../common/in-progress-state-message';

interface DevBoxStateMessageProps {
    state: DevBoxState;
}

const messages = defineMessages({
    spinnerAriaLabel: {
        id: 'DevBoxStateMessage_Spinner_AriaLabel',
        defaultMessage: 'Creating your dev box',
        description: 'Aria label for spinner tracking progress of dev box creation in dev box card',
    },
});

export const DevBoxStateMessage: React.FC<DevBoxStateMessageProps> = React.memo((props: DevBoxStateMessageProps) => {
    const { state } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    switch (state) {
        case DevBoxState.Creating:
            return (
                <InProgressStateMessage ariaLabel={formatMessage(messages.spinnerAriaLabel)}>
                    <FormattedMessage
                        id="DevBoxStateMessage_CreatingMessage_Text"
                        defaultMessage="Creating..."
                        description="Message informing user that dev box creation is in progress"
                    />
                </InProgressStateMessage>
            );
        case DevBoxState.Deleting:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_DeletingState_Text"
                        defaultMessage="Deleting..."
                        description="Text informing user that dev box is in the process of being deleted."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Running:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_RunningState_Text"
                        defaultMessage="Running"
                        description="Text informing user that dev box is running."
                    />
                </AriaLiveWrapper>
            );

        case DevBoxState.Starting:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_StartingState_Text"
                        defaultMessage="Starting..."
                        description="Text informing user that dev box is starting."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Stopped:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_StoppedState_Text"
                        defaultMessage="Stopped"
                        description="Text informing user that dev box is stopped."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Repaired:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_RepairedState_Text"
                        defaultMessage="Repaired"
                        description="Text informing user that dev box has been repaired."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Stopping:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_StoppingState_Text"
                        defaultMessage="Shutting down..."
                        description="Text informing user that dev box is in the process of being stopped."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Hibernated:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_HibernatedState_Text"
                        defaultMessage="Hibernated"
                        description="Text informing user that the dev box is hibernated."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Hibernating:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_HibernatingState_Text"
                        defaultMessage="Hibernating..."
                        description="Text informing user that the dev box is in the process of being hibernated."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Resuming:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_ResumingState_Text"
                        defaultMessage="Resuming..."
                        description="Text informing user that the dev box is in the process of being resumed from hibernate."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Restarting:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_RestartingState_Text"
                        defaultMessage="Restarting..."
                        description="Text informing user that the dev box is in the process of being restarted."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Restoring:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_RestoringState_Text"
                        defaultMessage="Restoring..."
                        description="Text informing user that the dev box is in the process of being restored."
                    />
                </AriaLiveWrapper>
            );
        case DevBoxState.Restored:
            return (
                <AriaLiveWrapper>
                    <FormattedMessage
                        id="DevBoxStateMessage_RestoredState_Text"
                        defaultMessage="Restored"
                        description="Text informing user that the dev box has been restored."
                    />
                </AriaLiveWrapper>
            );
        // TODO: Unknown states are not expected, but showing another state is also not accurate.
        // For now the state text is empty if state is unknown.
        default:
            return <></>;
    }
});

export default DevBoxStateMessage;
