import { SagaIterator } from 'redux-saga';
import { all, put, select } from 'redux-saga/effects';
import { ListSnapshotsResponse } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isAggregatedFailure } from '../../../models/common';
import { combineResults } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import {
    listSnapshots,
    loadSnapshots as loadSnapshotsActionCreator,
    loadSnapshotsError,
    loadSnapshotsFailed,
    loadSnapshotsSuccess,
} from '../../actions/snapshot/snapshot-action-creators';
import { LoadSnapshotsAction } from '../../actions/snapshot/snapshot-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getIdsForDevBoxesHavingSnapshots } from './selectors';

export function* loadSnapshotsSaga(action: LoadSnapshotsAction): SagaIterator {
    try {
        const ids: string[] = yield select(getIdsForDevBoxesHavingSnapshots);

        const responses: ListSnapshotsResponse[] = yield all(ids.map((id) => putAndAwait(listSnapshots({ id }))));

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadSnapshots, ...failures);
            yield put(loadSnapshotsFailed({ failure }));
        } else {
            yield put(loadSnapshotsSuccess());
        }

        yield resolveAction(action, completeResult);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadCustomizationGroups);
        yield put(loadSnapshotsError({ error }));
        yield rejectAction(action, error);
    }
}

export function* loadSnapshotsListenerSaga(): SagaIterator {
    yield takeEvery(loadSnapshotsActionCreator, loadSnapshotsSaga);
}
